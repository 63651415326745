import Button, { ButtonProps } from "@mui/material/Button";
import { useSetFlowStage } from "hooks/useAuth";
import { useCallback } from "react";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    form: {
        position: "relative",
        width: "100%",
        maxWidth: 600,
    },
    link: {
        color: theme.palette.primary.main,
    },
    row: {
        marginTop: "1em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
}));

function AuthLink({ onClick, className, ...props }: ButtonProps) {
    const { classes, cx } = useStyles();

    return <Button className={cx(classes.link, className)} onClick={onClick} {...props} />;
}

export type AuthLinkRowProps = React.HTMLAttributes<HTMLDivElement>;
export function AuthLinkRow(props: AuthLinkRowProps) {
    const { classes } = useStyles();

    return <div className={classes.row} {...props} />;
}

export type AuthLinkSignInProps = {
    label?: string;
    children?: React.ReactNode;
};
export function AuthLinkSignIn({ label = "Sign In", children }: AuthLinkSignInProps) {
    const setFlowStage = useSetFlowStage();
    const onShowSignIn = useCallback(() => {
        setFlowStage("COGNITO_SIGN_IN");
    }, [setFlowStage]);

    return <AuthLink onClick={onShowSignIn}>{children ?? label}</AuthLink>;
}

export type AuthLinkLinkForgotPasswordProps = {
    label?: string;
    children?: React.ReactNode;
};
export function AuthLinkForgotPassword({ label = "Reset Password", children }: AuthLinkLinkForgotPasswordProps) {
    const setFlowStage = useSetFlowStage();

    const onShowForgotPassword = useCallback(() => {
        setFlowStage("COGNITO_COGNITO_FORGOT_PASSWORD");
    }, [setFlowStage]);

    return <AuthLink onClick={onShowForgotPassword}>{children ?? label}</AuthLink>;
}

export type AuthLinkLinkForgotPasswordSubmitProps = {
    label?: string;
    children?: React.ReactNode;
};
export function AuthLinkForgotPasswordSubmit({
    label = "I Have a Code",
    children,
}: AuthLinkLinkForgotPasswordSubmitProps) {
    const setFlowStage = useSetFlowStage();

    const onShowForgotPasswordSubmit = useCallback(() => {
        setFlowStage("COGNITO_FORGOT_PASSWORD_SUBMIT");
    }, [setFlowStage]);

    return <AuthLink onClick={onShowForgotPasswordSubmit}>{children ?? label}</AuthLink>;
}

export default AuthLink;
