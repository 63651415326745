import FormTitle from "components/Form/FormTitle";
import FormValidation from "components/Form/FormValidation";
import TextField from "components/Form/TextField";
import { TextCaption } from "components/Text";
import { FORM_ERROR } from "final-form";
import { useUsernameValidate } from "hooks/useAuth";
import { useCallback } from "react";
import { Form as FinalForm } from "react-final-form";

import { Spacer } from "@holibob-packages/ui-core/components";
import { makeStyles } from "@holibob-packages/ui-core/style";

import AuthFormContainer from "./AuthFormContainer";
import AuthSubmitButton from "./AuthSubmitButton";

const useStyles = makeStyles()({
    validation: {
        textAlign: "center",
        marginBottom: 20,
    },
    requestAccount: {
        marginTop: "1em",
        textAlign: "center",
    },
});

function RequestAccount() {
    const { classes } = useStyles();
    return (
        <div className={classes.requestAccount}>
            <TextCaption>
                To request an account, please contact
                <a href="mailto:partnersupport@holibob.tech"> partnersupport@holibob.tech</a>
            </TextCaption>
        </div>
    );
}

function useUsernameValidateForm({ onComplete }: $TSFixMe) {
    const usernameValidate = useUsernameValidate();

    const onSubmit = useCallback(
        async ({ username }: { username: string }) => {
            try {
                const response = await usernameValidate(username);

                if (onComplete) onComplete(response);
                return undefined;
            } catch (error) {
                return { [FORM_ERROR]: error.message };
            }
        },
        [usernameValidate, onComplete]
    );

    return {
        formProps: { onSubmit },
    };
}

function UsernameValidateFormContent() {
    const { classes } = useStyles();

    return (
        <form>
            <FormTitle>Enter your Username or Email</FormTitle>
            <Spacer sm />
            <TextField
                data-testid="signin-Username"
                name="username"
                autoComplete="username"
                label="Username or Email"
                autoFocus
                required
            />
            <Spacer sm />
            <FormValidation className={classes.validation} />
            <Spacer sm />
            <AuthSubmitButton>Continue</AuthSubmitButton>
            <RequestAccount />
        </form>
    );
}

export function UsernameValidateForm({ onComplete, className }: $TSFixMe) {
    const { formProps } = useUsernameValidateForm({ onComplete });

    return (
        <AuthFormContainer className={className}>
            <FinalForm {...formProps} component={UsernameValidateFormContent} />
        </AuthFormContainer>
    );
}

export default UsernameValidateForm;
