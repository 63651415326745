import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    formContainer: {
        position: "relative",
        width: "100%",
        maxWidth: 600,
        backgroundColor: "white",
    },
});

export type AuthFormContainerProps = {
    className?: string;
    children: React.ReactNode;
};
export const AuthFormContainer = ({ className, children }: AuthFormContainerProps) => {
    const { classes, cx } = useStyles();

    return (
        <Card className={cx(classes.formContainer, className)}>
            <CardContent>{children}</CardContent>
        </Card>
    );
};

export default AuthFormContainer;
