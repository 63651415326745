import { createContext, useState } from "react";

const initialState = {
    readOnly: null,
};

const FormContext = createContext(initialState);

export const FormStateProvider = ({ children, readOnly }: $TSFixMe) => {
    const [state] = useState({ ...initialState, readOnly });

    return <FormContext.Provider value={state}>{children}</FormContext.Provider>;
};

export default FormContext;
