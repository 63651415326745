import { AuthContext, useAuthContextValue } from "hooks/useAuth";

export type AuthProviderProps = {
    children: JSX.Element;
};
export function AuthProvider({ children }: AuthProviderProps) {
    const value = useAuthContextValue();

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
