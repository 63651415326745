import { gql, useMutation } from "@apollo/client";
import { useCallback } from "react";

const mutation = gql`
    mutation useSecurityPrincipalUserMigrate($input: SecurityPrincipalUserMigrateInput!) {
        securityPrincipalUserMigrate(input: $input)
    }
`;

export function useSecurityPrincipalUserMigrate() {
    const [mutate, { loading, error }] = useMutation(mutation);

    const securityPrincipalMigrate = useCallback(
        async (values: $TSFixMe) => {
            const variables = { input: values };
            await mutate({ variables });
        },
        [mutate]
    );

    return [
        securityPrincipalMigrate,
        {
            loading,
            error,
        },
    ] as const;
}

export default useSecurityPrincipalUserMigrate;
