import CognitoSignInForm from "containers/Authenticator/CognitoSignInForm";
import CompleteNewPasswordForm from "containers/Authenticator/CompleteNewPasswordForm";
import UsernameValidateForm from "containers/Authenticator/UsernameValidateForm";
import { Stage, useAuthStage } from "hooks/useAuth";

import { ErrorBoundary } from "@holibob-packages/ui-core/components";

const stageMap: Partial<Record<Stage, React.ComponentType<{ className?: string }>>> = {
    VALIDATE_USERNAME: UsernameValidateForm,
    COGNITO_SIGN_IN: CognitoSignInForm,
    COGNITO_COMPLETE_NEW_PASSWORD: CompleteNewPasswordForm,
};

export function Authenticator(props: { className?: string }) {
    const stage = useAuthStage();

    const Stage = stageMap[stage] ?? UsernameValidateForm;

    return (
        <ErrorBoundary>
            <Stage className={props.className} />;
        </ErrorBoundary>
    );
}

export default Authenticator;
