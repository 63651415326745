import FormContext from "contexts/FormContext";
import { useContext } from "react";

export const useForm = () => useContext(FormContext);

export const useFormState = () => {
    const state = useForm();
    return state;
};

export const useIsFormReadOnly = () => {
    const state = useFormState();
    const { readOnly } = state;

    return !!readOnly;
};

export const useIsFieldReadOnly = (readOnlyProp: $TSFixMe) => {
    const readOnly = useIsFormReadOnly();

    return !!(readOnly || readOnlyProp);
};

export default useForm;
