import { FormSubmitButton } from "components/Form/FormSubmitButton";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()({
    submitContainer: {
        display: "flex",
        justifyContent: "center",
    },
});

export type AuthSubmitButtonProps = {
    className?: string;
    children?: React.ReactNode;
};
export const AuthSubmitButton = ({ className, children }: AuthSubmitButtonProps) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.submitContainer, className)}>
            <FormSubmitButton>{children}</FormSubmitButton>
        </div>
    );
};

export default AuthSubmitButton;
