import Text from "components/Text";
import { CSSProperties, ReactNode, memo } from "react";
import { Field } from "react-final-form";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    validation: {
        fontSize: 13,
        fontWeight: "bold",
        color: theme.palette.error.main,
        textAlign: "left",
        paddingTop: 5,
        paddingBottom: 0,
    },
}));

type ValidationMessageProps = {
    children: ReactNode;
    style?: CSSProperties;
};

export const ValidationMessage = ({ children }: ValidationMessageProps) => {
    const { classes } = useStyles();
    return <Text className={classes.validation}>{children}</Text>;
};

type FieldValidationProps = {
    touched?: boolean;
    error?: string;
    warning?: string;
    submitError?: string;
    style?: CSSProperties;
};

export const FieldValidation = memo(({ style, touched, error, warning, submitError }: FieldValidationProps) => {
    const _error = touched ? error ?? submitError : undefined;
    const _warning = touched ? warning : undefined;

    if (_error) {
        return <ValidationMessage style={style}>{_error}</ValidationMessage>;
    }
    if (_warning) {
        return <ValidationMessage style={style}>{_warning}</ValidationMessage>;
    }

    return null;
});

FieldValidation.displayName = "FieldValidation";

type FieldValidationSpyProps = {
    name: string;
};

export const FieldValidationSpy = ({ name, ...props }: FieldValidationSpyProps) => {
    if (!name) return null;
    return (
        <Field
            name={name}
            render={({ meta }) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'warning' does not exist on type 'FieldMe... Remove this comment to see the full error message
                const { touched, error, warning, submitError } = meta;
                // prettier-ignore
                return <FieldValidation touched={touched} error={error} warning={warning} submitError={submitError} {...props} />;
            }}
        />
    );
};

export default FieldValidationSpy;
