import holibobBG from "assets/images/admin/signin_bg.jpg";
import Layout, { LayoutContent, LayoutProps } from "components/Layout";
import Authenticator from "containers/Authenticator";

import { Flex } from "@holibob-packages/ui-core/components";
import { makeStyles } from "@holibob-packages/ui-core/style";

import { Header } from "./Header/Header";

const useStyles = makeStyles()({
    background: {
        backgroundColor: "#DEE6E5",
        height: "100vh",
        width: "100%",
        backgroundImage: `url(${holibobBG.src})`,
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
});

export const SignInPage = (props: Omit<LayoutProps, "children">) => {
    const { classes } = useStyles();

    return (
        <Layout header={<Header />} {...props}>
            <div className={classes.background} />
            <LayoutContent>
                <Flex center>
                    <Authenticator />
                </Flex>
            </LayoutContent>
        </Layout>
    );
};

export default SignInPage;
