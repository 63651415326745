import FieldValidation from "components/Form/FieldValidation";

import TextInputField, { TextInputFieldProps } from "./TextInputField";
import fieldDecorator from "./fieldDecorator";

//FIXME unused meta var - spread props - safe to pass meta?

export const SecureTextInputField = fieldDecorator<unknown, TextInputFieldProps<unknown>>(
    ({ input, meta: _meta, ...props }) => {
        const { value, onBlur, onChange, onFocus } = input;

        return (
            <>
                <TextInputField
                    variant={"outlined"}
                    fullWidth
                    type="password"
                    autoComplete="current-password"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    {...props}
                />
                <FieldValidation name={input.name} />
            </>
        );
    }
);

export default SecureTextInputField;
