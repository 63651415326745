import i18next from "i18next";

export type Validator<ValueType = unknown> = (value?: ValueType) => string | undefined;

export function compose<ValueType>(...validators: Array<Validator<ValueType>>): Validator<ValueType> {
    return (value) => {
        return validators.reduce((error: string | undefined, validator) => error ?? validator(value), undefined);
    };
}

export const required: Validator = (value) => {
    const label = i18next.t("validation:label.required");
    return value === undefined || value === null ? label : undefined;
};

export const mustBeNumber: Validator = (value) => {
    if (value === undefined) return undefined;
    return isNaN(value as number) ? "Must be a number" : undefined;
};

export const mustBeInteger: Validator = (value) => {
    if (!value) return undefined;

    const number = Number(value);
    const isInt = Number.isInteger(number);
    return isInt ? undefined : "Must be a whole number";
};

// prettier-ignore
export const minValue = (min: number): Validator<number> => (value) => {
    if (value === undefined) return undefined;
    return isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
};

export const oneOf = (...enums: Array<string>): Validator => {
    return (value) => {
        if (value === undefined) return undefined;
        return enums.includes(value as string) ? undefined : `Must be one of the following values: ${enums.join(", ")}`;
    };
};

export const hasUpperAndLower: Validator<string> = (value) => {
    if (value === undefined) return undefined;
    return value.toUpperCase() !== value && value.toLowerCase() !== value
        ? undefined
        : "Must include upper and lower case letter";
};

export const hasSpecial: Validator<string> = (value) => {
    if (value === undefined) return undefined;
    return /[~`!#$%^&*+=\-[\]\\;,/{}|\\":<>?]/.test(value) ? undefined : "Must have special character";
};

export const hasLength = (len: number): Validator<string> => {
    return (value = "") => {
        return value.length >= len ? undefined : `Must be at least ${len} characters long`;
    };
};

export const isLength = (len: number): Validator<string> => {
    return (value = "") => {
        return value.length === len ? undefined : `Must be exactly ${len} characters long`;
    };
};

export const hasNumber: Validator<string> = (value) => {
    if (value === undefined) return undefined;
    return /\d/.test(value) ? undefined : "Must include number";
};

export const email: Validator<string | null> = (value) => {
    const label = i18next.t("validation:label.email");
    if (value === undefined || value === null) return undefined;
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? undefined : label;
};

export const url: Validator<string> = (value) => {
    if (value === undefined) return undefined;
    const isValid = isValidHttpUrl(value);
    return isValid ? undefined : "Invalid url";
};

export const password = compose(hasLength(8), hasUpperAndLower);

export const isValidHttpUrl = (value: string) => {
    try {
        const newUrl = new URL(value);
        return newUrl.protocol === "http:" || newUrl.protocol === "https:";
    } catch (err) {
        return false;
    }
};
