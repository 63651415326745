import Text, { TextBaseProps } from "components/Text";
import { useFormState } from "react-final-form";

import { makeStyles } from "@holibob-packages/ui-core/style";

const useStyles = makeStyles()((theme) => ({
    validation: {
        fontSize: 13,
        fontWeight: "bold",
        color: theme.palette.error.main,
        marginTop: 15,
    },
}));

function useFormErrorMessage(): Record<string, unknown> | null {
    const state = useFormState();
    const { submitError, submitErrors } = state;

    if (submitError) {
        return submitError;
    }
    if (submitErrors && Object.values(submitErrors)[0]) {
        return Object.values(submitErrors)[0];
    }

    return null;
}

export function ValidationMessage({ className, ...props }: Omit<TextBaseProps, "children">) {
    const { classes, cx } = useStyles();
    return <Text className={cx(classes.validation, className)} {...props} />;
}

export function FormValidation(props: TextBaseProps) {
    const errorMessage = useFormErrorMessage();

    if (!errorMessage) return null;

    return <ValidationMessage {...props}>{errorMessage}</ValidationMessage>;
}

export default FormValidation;
