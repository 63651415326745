import FieldValidation from "components/Form/FieldValidation";
import { compose, required, Validator } from "components/Form/validators";
import LabelValue from "components/LabelValue";
import TextInput, { TextInputProps } from "components/TextInput";
import { useIsFieldReadOnly } from "hooks/useForm";
import { useField, UseFieldConfig } from "react-final-form";

type TextFieldProps<FieldValue> = { name: string; validate?: Validator } & TextInputProps & UseFieldConfig<FieldValue>;

export function TextField<FieldValue extends string>({
    name,
    validate,
    readOnly: readOnlyProp,
    format,
    formatOnBlur,
    parse,
    ...props
}: TextFieldProps<FieldValue>) {
    const { label, required: requiredProp } = props;

    const validateList = validate ? [validate] : [];
    if (requiredProp) validateList.push(required);

    const field = useField<FieldValue>(name, {
        validate: compose(...validateList),
        format,
        parse,
        formatOnBlur,
    });

    const { input } = field;
    const { value, onBlur, onChange, onFocus } = input;

    const readOnly = useIsFieldReadOnly(readOnlyProp);

    if (readOnly) return <LabelValue label={label} value={value || "Not Set"} />;

    return (
        <>
            <TextInput
                variant={"outlined"}
                fullWidth
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                name={name}
                {...props}
            />
            <FieldValidation name={input.name} />
        </>
    );
}

export default TextField;
