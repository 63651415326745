import FormTitle from "components/Form/FormTitle";
import SecureTextField from "components/Form/SecureTextField";
import TextField from "components/Form/TextField";
import { compose, hasLength, hasSpecial, hasUpperAndLower, required } from "components/Form/validators";
import { TextBody } from "components/Text";
import { FORM_ERROR } from "final-form";
import { useAuthState, useAuthStateSecurityPrincipalUserId, useCognitoCompleteNewPassword } from "hooks/useAuth";
import { useCallback } from "react";
import { Form as FinalForm } from "react-final-form";

import { Spacer } from "@holibob-packages/ui-core/components";

import AuthFormContainer from "./AuthFormContainer";
import { AuthLinkRow, AuthLinkSignIn } from "./AuthLink";
import AuthSubmitButton from "./AuthSubmitButton";

type CompleteNewPasswordFormProps = {
    onComplete?: () => void;
    className?: string;
};
export function CompleteNewPasswordForm({ onComplete, className }: CompleteNewPasswordFormProps) {
    const state = useAuthState();
    const cognitoCompleteNewPassword = useCognitoCompleteNewPassword();
    const securityPrincipalUserId = useAuthStateSecurityPrincipalUserId();
    const { password, username } = state;

    const onSubmit = useCallback(
        async ({ currentPassword, newPassword }: { currentPassword: string; newPassword: string }) => {
            try {
                await cognitoCompleteNewPassword({
                    securityPrincipalUserId,
                    currentPassword,
                    newPassword,
                    returnTo: "/",
                });
                if (onComplete) onComplete();
                return undefined;
            } catch (error) {
                const { message } = error;
                const errorMsg = message;

                return { [FORM_ERROR]: errorMsg };
            }
        },
        [cognitoCompleteNewPassword, onComplete, securityPrincipalUserId]
    );

    return (
        <AuthFormContainer className={className}>
            <FinalForm onSubmit={onSubmit} initialValues={{ username, currentPassword: password }}>
                {() => {
                    return (
                        <form>
                            <FormTitle>New Password Required</FormTitle>
                            <Spacer sm />
                            <TextField
                                name="username"
                                label="Username or Email"
                                placeholder={"Enter username or email"}
                                autoFocus={!username}
                                validate={required}
                            />
                            <Spacer sm />
                            <SecureTextField
                                name="currentPassword"
                                label={"Current Password"}
                                placeholder={"Enter current password"}
                                autoFocus={!password}
                                validate={compose(required, hasLength(8))}
                            />
                            <Spacer />
                            <TextBody>
                                In order to continue you must choose a new password that will be known only to yourself.
                            </TextBody>
                            <Spacer sm />
                            <SecureTextField
                                name="newPassword"
                                label={"New Password"}
                                placeholder={"Enter new password"}
                                autoFocus={!!username}
                                validate={compose(required, hasLength(8), hasUpperAndLower, hasSpecial)}
                            />
                            <Spacer sm />

                            <AuthSubmitButton>Submit</AuthSubmitButton>

                            <AuthLinkRow>
                                <AuthLinkSignIn />
                            </AuthLinkRow>
                        </form>
                    );
                }}
            </FinalForm>
        </AuthFormContainer>
    );
}

export default CompleteNewPasswordForm;
