import CircularProgress from "@mui/material/CircularProgress";
import { ButtonPrimary, ButtonProps } from "components/Button";
import { ReactNode } from "react";
import { FormSpy } from "react-final-form";

import { withStyles } from "@holibob-packages/ui-core/style";

const ColorCircularProgress = withStyles(CircularProgress, {
    root: {
        color: "#FFF",
        marginLeft: 10,
    },
});

type FormSubmitButtonProps = {
    readOnly?: boolean;
    failTitle?: string;
    disabledOnInvalid?: boolean;
    disabledOnPristine?: boolean;
    loader?: boolean;
    children?: ReactNode;
} & ButtonProps;

export const FormSubmitButton = ({
    readOnly,
    children,
    title = "Submit",
    failTitle,
    disabledOnInvalid,
    disabledOnPristine,
    size = "medium",
    loader = true,
    ...props
}: FormSubmitButtonProps) => {
    if (readOnly) return null;
    return (
        <FormSpy>
            {(fsProps) => {
                const { submitting, submitFailed, invalid, form, pristine } = fsProps;

                const _title = submitFailed && failTitle ? failTitle : title;

                const content = children ?? _title;

                const disabled = !!(disabledOnInvalid && invalid) || !!(disabledOnPristine && pristine);

                const onClick = (event: React.MouseEvent) => {
                    event.stopPropagation();
                    event.preventDefault();
                    void form.submit();
                };

                return (
                    <>
                        <ButtonPrimary size={size} disabled={disabled} onClick={onClick} type="submit" {...props}>
                            {content}
                            {submitting && loader && <ColorCircularProgress size={20} />}
                        </ButtonPrimary>
                    </>
                );
            }}
        </FormSpy>
    );
};

export default FormSubmitButton;
