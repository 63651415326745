import { gql } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

const query = gql`
    query useUsernameValidate($username: String!) {
        usernameValidate(username: $username) {
            authenticationMethod
            securityPrincipalUserId
        }
    }
`;

type UsernameValidate = {
    authenticationMethod: string;
    securityPrincipalUserId: string;
};

export function useUsernameValidateLazy() {
    const client = useApolloClient();

    const fetch = useCallback(
        async (username: string) => {
            const variables = { username };

            const response = await client.query<{ usernameValidate: UsernameValidate }>({ query, variables });

            return response.data.usernameValidate;
        },
        [client]
    );

    return [fetch] as const;
}
