import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import FieldValidation from "components/Form/FieldValidation";
import TextInput, { TextInputProps } from "components/TextInput";

import { useToggle } from "@holibob-packages/ui-core/hooks";

import fieldDecorator from "./fieldDecorator";
import { Validator } from "./validators";

export const SecureTextField = fieldDecorator<string, TextInputProps & { validate: Validator<string> }>(
    ({ input, meta: _meta, ...props }) => {
        const { value, onBlur, onChange, onFocus } = input;

        const [showPassword, toggleShowPassword] = useToggle(false);

        return (
            <>
                <TextInput
                    variant={"outlined"}
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    onFocus={onFocus}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword}
                                    onMouseDown={(event) => event.preventDefault()}
                                    size="large"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...props}
                />
                <FieldValidation name={input.name} />
            </>
        );
    }
);

export default SecureTextField;
