import FieldValidation from "components/Form/FieldValidation";
import { Validator, required, compose } from "components/Form/validators";
import TextInput, { TextInputProps } from "components/TextInput";
import { useField, UseFieldConfig } from "react-final-form";

export type TextInputFieldProps<FieldValue> = {
    name: string;
    validate?: Validator<FieldValue>;
    format?: UseFieldConfig<FieldValue>["format"];
    parse?: UseFieldConfig<FieldValue>["parse"];
} & TextInputProps;
function TextInputField<ValidatorInput>({
    name,
    validate,
    format,
    parse,
    ...props
}: TextInputFieldProps<ValidatorInput>) {
    const validation: Validator<ValidatorInput>[] = [];
    if (validate) {
        validation.push(validate);
    }
    if (props.required) validation.push(required);

    const { input } = useField(name, {
        format,
        parse,
        validate: validation.length ? compose(...validation) : undefined,
    });

    const { value, onBlur, onChange, onFocus } = input;

    return (
        <>
            <TextInput
                variant={"outlined"}
                fullWidth
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                onFocus={onFocus}
                {...props}
            />
            <FieldValidation name={input.name} />
        </>
    );
}

export default TextInputField;
