import FormTitle from "components/Form/FormTitle";
import FormValidation from "components/Form/FormValidation";
import SecureTextInputField from "components/Form/SecureTextInputField";
import TextField from "components/Form/TextField";
import { FORM_ERROR } from "final-form";
import { useAuthStateSecurityPrincipalUserId, useAuthStateUsername, useSignIn } from "hooks/useAuth";
import { useSearchParams } from "next/navigation";
import { useCallback } from "react";
import { Form as FinalForm } from "react-final-form";

import { Spacer } from "@holibob-packages/ui-core/components";
import { makeStyles } from "@holibob-packages/ui-core/style";

import AuthFormContainer from "./AuthFormContainer";
import AuthSubmitButton from "./AuthSubmitButton";

const useStyles = makeStyles()({
    validation: {
        textAlign: "center",
        marginBottom: 20,
    },
    requestAccount: {
        marginTop: "1em",
        textAlign: "center",
    },
});

type UseCognitoSignInFormProps = {
    returnTo: string;
};
function useCognitoSignInForm({ returnTo }: UseCognitoSignInFormProps) {
    const username = useAuthStateUsername();
    const securityPrincipalUserId = useAuthStateSecurityPrincipalUserId();
    const signIn = useSignIn();

    const initialValues = { username };

    const onSubmit = useCallback(
        async ({ password }: { password: string }) => {
            try {
                await signIn({ password, securityPrincipalUserId, returnTo });
                return undefined;
            } catch (error) {
                return { [FORM_ERROR]: error.message };
            }
        },
        [returnTo, securityPrincipalUserId, signIn]
    );

    return {
        formProps: {
            onSubmit,
            initialValues,
        },
    };
}

export type CognitoSignInFormContentProps = {
    initialValues: {
        username: string | null;
    };
};
export function CognitoSignInFormContent(props: CognitoSignInFormContentProps) {
    const { classes } = useStyles();
    const { initialValues } = props;
    const readOnly = !!initialValues.username;
    const label = readOnly ? "User" : "Username or Email";

    return (
        <form>
            <FormTitle>Sign In To Your Account</FormTitle>
            <Spacer sm />
            <TextField
                name="username"
                autoComplete="username"
                label={label}
                readOnly={readOnly}
                autoFocus={!readOnly}
                required
            />
            <Spacer sm />
            <SecureTextInputField
                name="password"
                autoComplete="password"
                data-testid="signin-Password"
                label="Password"
                autoFocus={readOnly}
            />
            <FormValidation className={classes.validation} />
            <Spacer />
            <AuthSubmitButton>Sign In </AuthSubmitButton>
        </form>
    );
}

export type CognitoSignInFormProps = {
    className?: string;
};
export function CognitoSignInForm({ className }: CognitoSignInFormProps) {
    const searchParams = useSearchParams();
    const { formProps } = useCognitoSignInForm({ returnTo: searchParams.get("returnTo") ?? "/" });

    return (
        <AuthFormContainer className={className}>
            <FinalForm {...formProps} component={CognitoSignInFormContent} />
        </AuthFormContainer>
    );
}

export default CognitoSignInForm;
